import { Link } from "gatsby";
import React from "react";
import { extractCaption, getAuthorUrl, getCategoryUrl, limitPostExcerpt } from "../helpers/blog-helpers";
import sanitize from "../helpers/html-sanitizer";

const PostInfo = ({ post }) => {
  const tags = post.categories?.nodes || [];
  const authorName = post.author?.node?.name;
  const authorSlug = post.author?.node?.slug;
  const postDate = post.date;
  return (
    <p className="text-primary mb-1">
      By <Link to={getAuthorUrl(authorSlug)}>{authorName}</Link> |{" "}
      <b>{postDate}</b> |
      {tags.map(({ slug, name }, index) => {
        if (index === tags.length - 1) {
          return (
            <React.Fragment key={`tag_${slug}`}>
            {" "}
            <Link key={`tag_${slug}`} to={getCategoryUrl(slug)}>
              {name}
            </Link>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={`tag_${slug}`}>
              {" "}
              <Link key={`tag_${slug}`} to={getCategoryUrl(slug)}>
                {name}
              </Link>
              ,
            </React.Fragment>
          );
        }
      })}
    </p>
  );
};

const BlogFeedEntry = ({ post }) => {
  const blogTitle = post.title;
  const imageSrc = post?.featuredImage?.node?.localFile?.publicURL;
  const caption = extractCaption(post);
  const blogIntroduction = limitPostExcerpt(post);
  const postLink = `/${post.slug}/`;

  return (
    <article className="card custom-cards text-left">
      <Link to={postLink}>
        <img alt={caption} className="w-100 mb-2" src={imageSrc} />
      </Link>
      <div className="card-body">
        <Link to={postLink}>
          <h4 className="mb-1">{blogTitle}</h4>
        </Link>
        <PostInfo post={post} />
        <div dangerouslySetInnerHTML={{ __html: sanitize(blogIntroduction) }} />
      </div>
    </article>
  );
};

export default BlogFeedEntry;