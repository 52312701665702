// FIXME: This IP should be 729solutions.com on production, when the links points to 729solutions.com/wp-content should be fixed
const oldWpIp = process.env.GATSBY_WORDPRESS_INSTANCE;
const wpImgPattern = /"\/wp-content\/uploads/g 
const oldWpAttachPath = new RegExp(`"${oldWpIp}/wp-content/uploads`, 'g');
const oldRootSite =new RegExp(`"${oldWpIp}"`, 'g');
const oldWpPath = new RegExp(oldWpIp, 'g');
const sanitize = (rawHtml, wpInstance = '') => {
  
  return rawHtml
  .replace(wpImgPattern, `"${wpInstance}/wp-content/uploads`)
  .replace(oldWpAttachPath, `"${wpInstance}/wp-content/uploads`)
  .replace(oldRootSite, '"/"')
  .replace(oldWpPath, '')
  .trim();
};

export default sanitize;
