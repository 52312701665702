import classNames from "classnames";
import React from "react";
import BlogFeedEntry from "./blog-feed-entry";

const FromTheBlogList = ({ title, containerClassname, posts }) => {
  return (
    <section>
      <div
        className={classNames("container", {
          [containerClassname]: containerClassname,
        })}
      >
        <div className="row">
          <div className="col">
            <h2>{title ? title : "From The Blog"}</h2>
          </div>
        </div>
        <div className="row mb-3">
          {posts.map((node) => (
            <div key={node.slug} className="col-lg-4">
              <BlogFeedEntry post={node} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FromTheBlogList;
