import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import FromTheBlogList from "./from-the-blog-list";

const BlogFeed = ({ title, containerClassname }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: [date], order: DESC }, limit: 3) {
        nodes {
          title
          excerpt
          date(formatString: "MMM D, YYYY")
          slug
          featuredImage {
            node {
              localFile {
                publicURL
              }
              caption
            }
          }
          categories {
            nodes {
              slug
              name
            }
          }
          author { node { slug name } }
        }
      }
    }
  `);

  return (
    <FromTheBlogList
      title={title}
      containerClassname={containerClassname}
      posts={data.allWpPost.nodes}
    />
  );
};

export default BlogFeed;
