const decodeHtmlCharCodes = (str) =>
  str.replace(/(&#(\d+);)/g, (_match, _capture, charCode) =>
    String.fromCharCode(charCode)
  );

export const toStandardPostFormat = (post, categoriesById) => {
  const image = post._embedded["wp:featuredmedia"] || [
    { link: "", source_url: "", caption: { rendered: "" } },
  ];
  const author = post._embedded?.author[0];
  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  return {
    title: decodeHtmlCharCodes(post.title?.rendered),
    featuredImage: {
      node: {
        localFile: { publicURL: image[0].source_url },
        caption: image[0]?.cation?.rendered,
      },
    },
    author: { node: { name: author?.name, slug: author?.slug } },
    excerpt: post.excerpt?.rendered,
    slug: post.slug,
    date: new Date(post.date).toLocaleDateString("en-us", dateOptions),
    categories: { nodes: post.categories.map((cId) => categoriesById[cId]) },
  };
};

export const extractCaption = (post) =>
  (post?.featuredImage?.node?.caption || "")
    .replace("<p>", "")
    .replace("</p>", "")
    .trim();

export const getPostUrl = (slug) => `/${slug}/`;
export const getAuthorUrl = (slug) => `/author/${slug}/`;
export const getCategoryUrl = (slug) => `/category/${slug}/`;

export const limitPostExcerpt = (post, charLimit = 255) => {
  const excerptSplit = post?.excerpt.split(/&hellip;/);
  const text = excerptSplit[0].substring(0, charLimit);
  const link = excerptSplit[1] ? excerptSplit[1].replace(/continue reading/ig, 'Read more') : excerptSplit[1];
  const html = `${text}&hellip; ${link}`;

  return html;
}